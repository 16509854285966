html,
body {
  width: 100%;
  height: 100%;
}

.wwb-page {
  display: block;
  position: relative;
}

.wwb-landing {
  display: block;
  position: relative;
  height: 400px;
}

.wwb-landing-content {
  display: grid;
  align-content: center;
  justify-content: center;
  position: relative;
  height: 100%;
  z-index: 2;
}

.wwb-landing-background {
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 10%,
      rgba(0, 0, 0, 0) 90%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../../images/landing-image.png") no-repeat;
  background-position: center 30%;
  background-size: auto 200%;
  /* filter: blur(2px); */
}

.wwb-banner-text {
  font-size: 3rem;
  text-transform: uppercase;
  font-family: var(--font-main);
  color: rgb(75, 75, 75);
  background-color: rgb(239 239 239 / 50%);
  padding: 1rem 2rem 0rem 1rem;
}

.wwb-banner-text > span {
  font-family: "Qwigley", cursive;
  font-weight: bold;
  font-style: normal;
  font-size: 7.5rem;
  color: var(--color-green);
}

.wwb-available-homes {
  background-color: white;
}

.wwb-available-homes-banner {
  display: grid;
  align-content: center;
  justify-content: center;
  padding: 1rem 0 1.5rem 0;
}

.wwb-available-homes-banner > span:first-child {
  font-family: var(--font-roboto);
  font-style: italic;
  color: var(--color-green);
  font-weight: bold;
  font-size: 1.5rem;
}

/* .wwb-available-homes-banner > span:first-child:after {
  position: absolute;
  content: "";
  border-top: 0.2rem solid var(--color-green);
  width: 5rem;
  transform: translateY(2.5rem) translateX(-8rem);
} */

.wwb-available-homes-banner-divider {
  display: block;
  position: relative;
  height: 0.15rem;
  width: 7rem;
  margin: 0.5rem auto 0 auto;
  background-color: var(--color-green);
}

.wwb-available-homes-banner > span:last-child {
  font-family: var(--font-roboto);
  font-style: italic;
  padding: 2rem 6rem 0 6rem;
}

.wwb-available-homes-banner.compact > span:last-child {
  padding: 2rem 2rem 0 2rem;
}

.wwb-map-container {
  background-color: white;
  padding: 0 5rem 2rem 5rem;
  z-index: 10;
  position: relative;
}

.wwb-map-container.compact {
  padding: 0 2rem 2rem 2rem;
}
