footer {
  font-family: var(--font-roboto);
}

.footer-container {
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 10fr 8fr 10fr;
  column-gap: 1rem;
  padding: 2%;
  background-color: var(--color-grey-1);
}

.footer-container.compact {
  grid-template-columns: 10fr 8fr;
}

.nav-footer-container {
  display: grid;
  grid-template-columns: 9fr 11fr;
  text-align: left;
}

.main-nav-footer {
  grid-column: 1;
}

.sub-nav-footer {
  grid-column: 2;
}

.footer-center {
  height: 100%;
  display: grid;
  color: var(--color-white);
  margin: auto;
  text-align: left;
}

.footer-center div:first-child {
  justify-self: center;
}

.footer-center a {
  text-decoration: none;
  color: inherit;
}

.footer-right {
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 15%;
  text-align: center;
}

.footer-socials {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: auto;
}

.footer-socials.compact {
  flex-direction: column;
}

.footer-socials a {
  color: var(--color-green);
  margin: 1rem;
}

.footer-socials.compact a {
  margin: 0.3rem;
}

.footer-details {
  display: block;
  position: relative;
  align-self: flex-end;
}

.footer-copyright {
  display: block;
  position: relative;
  color: var(--color-white);
  font-size: 0.8rem;
  align-self: flex-end;
}
