.homepage-content {
  position: relative;
  top: -10px;
  max-width: 1560px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-white);
}

.landing-container {
  position: relative;
  display: flex;
  height: 600px;
  width: 100%;
}

.landing-content {
  display: block;
  padding: 1rem;
  max-width: 400px;
  margin: auto;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-box-shadow: 0 0 20px 20px rgba(255, 255, 255, 0.75);
  box-shadow: 0 0 20px 20px rgba(255, 255, 255, 0.75);
}

.slogan-text {
  font-size: 4rem;
  font-weight: bold;
  text-transform: uppercase;
  font-family: var(--font-main);
}

.slogan-text-divider {
  display: block;
  position: relative;
  height: 0.15rem;
  width: 15rem;
  margin: 0rem auto 0.5rem auto;
  background-color: var(--color-green);
}

.company-blurb {
  display: block;
  font-family: var(--font-roboto);
  text-align: center;
  padding: 0px 2rem;
}

.mission-statement {
  display: grid;
  position: relative;
  padding: 1rem 10% 3rem 10%;
  font-size: 1rem;
  color: black;
  font-family: var(--font-roboto);
}

.mission-statement > h1 {
  color: var(--color-black);
  font-weight: bold;
  padding-top: 1rem 0;
}

.mission-statement span:nth-child(3) {
  color: var(--color-green);
  font-style: italic;
  font-size: 1.2rem;
  padding-bottom: 1rem;
}

.mission-statement-divider {
  display: block;
  position: relative;
  top: -13px;
  height: 0.15rem;
  width: 12rem;
  margin: 0rem auto;
  background-color: var(--color-green);
}

.landing-facts {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
  padding: 3rem 10%;
}

.landing-facts span:first-child {
  font-size: 3rem;
  font-style: italic;
  font-weight: bold;
  color: var(--color-green);
}

.landing-facts span:last-child {
  position: relative;
  top: -0.5rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--color-grey-2);
}

.gallery-preview {
  display: block;
  position: relative;
  padding: 1rem 10% 0 10%;
}

.gallery-preview > span {
  display: block;
  position: relative;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  font-family: var(--font-main);
  padding-bottom: 1rem;
  color: var(--color-black);
  font-kerning: none;
}

.gallery-preview > span > span {
  color: var(--color-green);
}

.gallery-preview > a {
  text-decoration: none;
}

.gallery-preview > a > button {
  display: block;
  position: relative;
  margin: 2rem auto;
  padding: 0.8rem;
}

.homepage-contact-header {
  display: block;
  position: relative;
  padding: 1rem 0;
}

.homepage-contact-header > span:first-child {
  display: block;
  position: relative;
  color: var(--color-black);
  font-size: 2.8rem;
  font-weight: bold;
  font-family: var(--font-roboto);
  padding: 1rem 0;
}

.homepage-contact-header > span:last-child {
  display: block;
  position: relative;
  color: var(--color-black);
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
  font-family: var(--font-roboto);
  padding: 1rem 0;
}

.homepage-contact {
  display: block;
  position: relative;
  padding: 1rem 20% 2rem 20%;
}

.homepage-contact.compact {
  display: block;
  position: relative;
  padding: 1rem 10%;
}
