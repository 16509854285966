.logo {
  padding: 10px;
  height: 80px;
  margin: 0 1rem;
}

.logo > img {
  width: 100%;
  height: 100%;
}

.logo.link > img {
  cursor: pointer;
}
