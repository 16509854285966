/* MOBILE NAV CSS */

.mobile-nav {
  display: block;
  position: fixed;
  height: auto;
  width: 60%;
  left: -60%;
  background-color: rgba(from var(--color-black) R G B / 0.8);
  z-index: 999;
  transition: height 0.1s ease-in-out;
}

.mobile-nav.open {
  transition: all 0.3s ease-in-out;
  left: 0;
}

.mobile-ul {
  padding: 1rem;
}

.mobile-menu-link {
  font-size: 1.2rem;
  padding: 0.2rem 0 0.2rem 0;
}

.mobile-menu-sub-link {
  padding: 0.4rem 0 0.4rem 1.5rem;
}
