header {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
}

header.header-compact {
  display: block;
  position: sticky;
  z-index: 99;
}

.title-banner {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  width: 100%;
  height: 100px;
  background-color: var(--color-black);
  z-index: 90;
}

.title-banner::after {
  position: absolute;
  content: "";
  border-top: 0.15rem solid white;
  width: 100%;
  transform: translateY(99px);
}

.title-banner-compact {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  width: 100%;
  height: 105px;
  background-color: var(--color-black);
}

#header-socials {
  position: relative;
  display: flex;
  margin: auto 0 auto 2rem;
  flex-wrap: nowrap;
}

#header-socials a {
  color: var(--color-green);
  margin: 1rem;
}

#phone-button {
  position: relative;
  color: white;
  background-color: green;
  border-radius: 10px;
  text-align: center;
  padding: 6px 40px;
  margin: auto 2rem auto auto;
  user-select: none;
}

.nav-bar {
  display: block;
  position: fixed;
  max-width: 1400px;
  width: calc(100vw - 20px);
  height: 50px;
  background-image: linear-gradient(
    to bottom,
    rgba(from var(--color-black) R G B / 1),
    rgba(from var(--color-black) R G B / 0.7)
  );
  z-index: 90;
}
