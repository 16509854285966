#contact-form-container {
  position: relative;
  display: block;
  transition: height 0.2s ease-in-out;
}

#contact-form {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  justify-items: start;
}

input[type="text"] {
  display: block;
  position: relative;
  width: calc(100% - 1rem);
  border: none;
  border-bottom: 1px solid var(--color-green);
  margin-top: 0.5rem;
  background-color: inherit;
}

#email-input {
  width: calc(100% - 3rem);
}

input[type="text"]:focus {
  outline: none;
  border: none;
  border-bottom: 2px solid var(--color-green);
}

label {
  display: block;
  position: relative;
  text-align: left;
  width: calc(100% - 2rem);
  padding: 0.5rem 1rem;
  font-family: var(--font-roboto);
}

#name-label {
  grid-row: 1;
  grid-column: 1;
}

#phone-label {
  grid-row: 1;
  grid-column: 2;
}

#email-label {
  grid-row: 2;
  grid-column: 1 / 3;
  width: 100%;
}

textarea {
  grid-row: 3;
  grid-column: 1 / 3;
  width: calc(100% - 3.5rem);
  margin: 1rem;
  padding: 0.5rem;
  resize: none;
}

textarea::placeholder {
  font-family: var(--font-roboto);
}

#submit-btn {
  grid-row: 4;
  grid-column: 1;
  border: 1px solid var(--color-green);
  background-color: white;
  margin: 0 1rem;
  padding: 0.5rem;
  font-family: var(--font-roboto);
  font-size: 1rem;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}

#submit-btn:hover {
  background-color: rgb(207, 207, 207);
}

#submit-btn:active {
  background-color: rgb(173, 173, 173);
}

#captcha-div {
  grid-column: 1 / 3;
  grid-row: 5;
  margin: 1rem auto 0 auto;
}
