html,
body {
  background-color: var(--color-black);
}

.App {
  text-align: center;
  max-width: 1400px;
  width: calc(100vw - 20px);
  margin: auto;
  border-left: 1px solid var(--color-grey-1);
  border-right: 1px solid var(--color-grey-1);
  background-color: var(--color-black);
  overflow: hidden;
}

.landing-container {
  user-select: none;
}

.landing-content {
  z-index: 5;
}

/* 
:root {
  --color-black: #080608;
  --color-white: #f3f3f3;
  --color-green: #1d741e;
  --color-grey: #333333;
  --color-red: #741d1d;
  --color-cyan: #1d7374;

  --font-main: "Fjalla One", "Fjalla One Fallback";
  --font-menu: "Montserrat", sans-serif;
  --font-roboto: Roboto, "Roboto Fallback";
  --font-merriweather: Merriweather, "Merriweather Fallback";
} 
*/

/*
  Color picker options:
  - #1d741e
  - #1d7374
  - #5bab15
*/
