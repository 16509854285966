html,
body {
  width: 100%;
  height: 100%;
}

.op-page {
  display: block;
  position: relative;
}

.op-landing {
  display: block;
  position: relative;
  height: 400px;
}

.op-landing-content {
  display: grid;
  align-content: center;
  justify-content: center;
  position: relative;
  height: 100%;
  z-index: 2;
}

.op-landing-background {
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 10%,
      rgba(0, 0, 0, 0) 90%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("images/landing-image.png") no-repeat;
  background-position: center 30%;
  background-size: auto 200%;
  /* filter: blur(2px); */
}

.op-banner-text {
  font-size: 3rem;
  text-transform: uppercase;
  font-family: var(--font-main);
  color: rgb(75, 75, 75);
  background-color: rgb(239 239 239 / 50%);
  padding: 1rem 2rem 0rem 1rem;
}

.op-banner-text > span {
  font-family: "Qwigley", cursive;
  font-weight: bold;
  font-style: normal;
  font-size: 7.5rem;
  color: var(--color-green);
}

.op-process-container {
  display: block;
  position: relative;
  background-color: white;
}

.op-process-banner {
  display: grid;
  align-content: center;
  justify-content: center;
  padding: 1rem 0 1.5rem 0;
  font-family: var(--font-main);
  font-size: 3rem;
}

.op-process-banner > span:last-child {
  color: var(--color-green);
  padding-top: 1rem;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.op-process-content {
  display: block;
  position: relative;
}

.op-process-tiles {
  display: grid;
  position: relative;
  z-index: 5;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 1rem;
  align-items: baseline;
  padding: 1rem;
}

.op-process-tiles.compact {
  grid-template-columns: calc(100%);
}

.op-process-background {
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 10%,
      rgba(0, 0, 0, 0) 90%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("images/landing-image.png") no-repeat;
  background-position: center 30%;
  background-size: auto 200%;
  filter: blur(2px);
}

.op-contact-us {
  display: grid;
  background-color: white;
  padding: 1rem 0;
}

.op-contact-us span {
  font: var(--font-roboto);
  padding: 1rem 0;
}

.op-contact-us > span:first-child {
  font-size: 3rem;
  letter-spacing: 0.1rem;
}
.op-contact-us > span:first-child:after {
  position: absolute;
  content: "";
  border-top: 0.2rem solid var(--color-green);
  width: 5rem;
  transform: translateY(4.7rem) translateX(-25rem);
}

.op-contact-us > span:last-child {
  font-size: 1.3em;
  color: var(--color-green);
  letter-spacing: 0.1rem;
}

.op-contact-us > span:last-child > span {
  letter-spacing: 0;
  font-weight: bold;
}
