html,
body {
  width: 100%;
  height: 100%;
}

#pageContainer {
  display: block;
  position: relative;
}

#landingContainer {
  display: block;
  position: relative;
  height: 300px;
}

#landingContent {
  display: grid;
  align-content: center;
  justify-content: center;
  position: relative;
  height: 120%;
  z-index: 2;
}

#landingBannerText {
  font-size: 3rem;
  text-transform: uppercase;
  font-family: var(--font-main);
  color: rgb(75, 75, 75);
  background-color: rgb(239 239 239 / 50%);
  padding: 1rem 2rem 0rem 1rem;
}

#landingBannerText > span {
  font-family: "Qwigley", cursive;
  font-weight: bold;
  font-style: normal;
  font-size: 7.5rem;
  color: var(--color-green);
}

#ownerIntro {
  position: relative;
  display: grid;
  background-color: var(--color-white);
}

#ownerIntro h1 {
  position: relative;
  padding: 0 8vw;
  font-family: var(--font-roboto);
}

#ownerIntro > img {
  position: relative;
  width: 200px;
  margin: auto;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  box-shadow: 1px 3px 8px black;
}

#ownerIntro > img:hover {
  transform: none;
}

#ownerIntro span:last-child {
  position: relative;
  padding: 2rem 8vw;
  font-family: var(--font-roboto);
  font-size: 1.1rem;
}

#ownerIntro span:last-child > span {
  font-style: italic;
}

#onealRustic,
#onealSmart {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 3vw;
  padding: 1rem 3vw;
  background-color: var(--color-white);
}

#onealRustic.compact,
#onealSmart.compact {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
}

#onealRustic > #onealRusticLeft,
#onealSmart > #onealSmartRight {
  position: relative;
  display: grid;
  align-content: center;
}

#onealSmart.compact > #onealSmartRight {
  grid-row: 1;
}

#onealRustic > #onealRusticLeft span:first-child,
#onealSmart > #onealSmartRight span:first-child {
  font-family: var(--font-roboto);
  font-style: italic;
  color: var(--color-green);
  padding: 1rem;
  font-weight: bold;
  font-size: 1.5rem;
}

#onealRustic > #onealRusticLeft span:first-child:after,
#onealSmart > #onealSmartRight span:first-child:after {
  position: absolute;
  content: "";
  border-top: 0.2rem solid var(--color-green);
  width: 5rem;
  transform: translateY(2.5rem) translateX(-6.6rem);
}

#onealRustic > #onealRusticLeft span:last-child,
#onealSmart > #onealSmartRight span:last-child {
  font-family: var(--font-roboto);
  padding: 1rem;
  font-size: 1.1rem;
}

#onealRustic > #onealRusticRight,
#onealSmart > #onealSmartLeft {
  display: block;
}

#onealRustic > #onealRusticRight img,
#onealSmart > #onealSmartLeft img {
  display: block;
  width: 100%;
  background-size: cover;
  background-position: center;
}

#aboutUsContactContainer {
  display: block;
  position: relative;
  width: 100%;
  background-color: var(--color-white);
}

#aboutUsContactContainer > span {
  display: block;
  position: relative;
  padding: 2rem 20vw;
  font-family: var(--font-roboto);
  font-style: italic;
  font-size: 1.2rem;
}

#aboutUsContactForm {
  padding: 1rem 15vw;
  margin: auto;
}
