html,
body {
  width: 100%;
  height: 100%;
}

.cu-page {
  display: block;
  position: relative;
}

.cu-landing {
  display: block;
  position: relative;
}

.cu-landing-content {
  display: grid;
  position: relative;
  align-content: center;
  align-items: baseline;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  z-index: 2;
}

.cu-landing-content.compact {
  grid-template-columns: 1fr;
}

.cu-landing-content [class^="cu-banner-"] {
  display: grid;
  color: white;
  margin: 6rem 2rem 3rem 2rem;
  padding: 1rem 1rem;
  row-gap: 1rem;
  text-shadow: 2px 2px 2px var(--color-black);
}

.cu-landing-content.compact [class^="cu-banner-"]:first-child {
  margin: 2rem;
}

.cu-landing-content.compact [class^="cu-banner-"]:last-child {
  margin: 2rem;
}

.cu-landing-content [class^="cu-banner-"] > span:nth-child(2) {
  font-family: var(--font-roboto);
  font-weight: bold;
  font-size: 1.3rem;
}
.cu-landing-content [class^="cu-banner-"] > span:nth-child(3) {
  font-family: var(--font-roboto);
  font-size: 1.1rem;
}

.cu-landing-content [class^="cu-banner-"] > span:nth-child(4) {
  font-family: var(--font-roboto);
  font-size: 1.3rem;
  font-style: italic;
}

.cu-landing-content [class^="cu-banner-"] > span:nth-child(4) > a {
  text-decoration: none;
  color: inherit;
}

.cu-landing-background {
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 10%,
      rgba(0, 0, 0, 0) 90%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../../images/landing-image.png") no-repeat;
  background-position: center 30%;
  background-size: auto 200%;
  /* filter: blur(2px); */
}

.cu-social-media {
  display: grid;
  background-color: white;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
}

.cu-social-media > span {
  grid-row: 1;
  grid-column: 1 / 4;
  font-family: var(--font-roboto);
  font-size: 2.5rem;
  font-weight: bold;
  padding: 2rem 0;
}

.cu-social-media img {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.instagram-qr-link {
  grid-row: 2;
  grid-column: 1;
}

.oneal-qr-link {
  grid-row: 2;
  grid-column: 2;
}

.facebook-qr-link {
  grid-row: 2;
  grid-column: 3;
}

.cu-contact-container {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  background-color: white;
}

.cu-contact-container.compact {
  grid-template-columns: 1fr;
}

.cu-contact-container > span:first-child {
  font-size: 3rem;
  text-transform: uppercase;
  font-family: var(--font-main);
  color: rgb(75, 75, 75);
  padding: 1rem 2rem 0rem 1rem;
  grid-row: 1;
  grid-column: 1;
}

.cu-contact-container > span:first-child > span {
  font-family: "Qwigley", cursive;
  font-weight: bold;
  font-style: normal;
  font-size: 7.5rem;
  color: var(--color-green);
}

.cu-contact-container > span:nth-child(2) {
  grid-row: 2;
  padding: 0 3rem 2rem 3rem;
  grid-column: 1;
}

.cu-contact-form {
  grid-row: 3;
  grid-column: 1;
  background-color: rgb(236, 236, 236);
  height: auto;
  margin: 0 2rem 1rem 2rem;
  padding: 1rem 0;
  transition: height 0.2s ease-in-out;
}

.cu-contact-side-image {
  grid-row: 1/4;
  grid-column: 2;
  background: url("images/doorwindows.jpg") no-repeat;
  background-position: 50% 80%;
  background-size: 80% 120%;
  margin: 2rem 2rem 1rem 2rem;
}
