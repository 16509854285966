.testimonial-border {
  width: 100%;
  background: var(--color-white);
  padding: 1rem 0;
}

.testimonial-border.right {
  background: linear-gradient(
    to bottom,
    var(--color-white) 0%,
    lightgrey 1%,
    lightgrey 99%,
    var(--color-white) 100%
  );
}

.testimonial-container {
  display: grid;
  grid-template-columns: 0.7fr auto 2fr;
  grid-template-rows: 1fr;
}

.testimonial-container.right {
  grid-template-columns: 2fr auto 0.7fr;
}

.testimonial-container.compact,
.testimonial-container.right.compact {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
}

/* default left position*/
.testimonial-portrait {
  grid-row: 1;
  grid-column: 1;
  display: block;
  padding: 0 1rem;
  margin: auto;
}

.testimonial-portrait.right {
  grid-row: 1;
  grid-column: 3;
  display: block;
  padding: 1rem 2rem;
}

.testimonial-portrait.compact,
.testimonial-portrait.right.compact {
  grid-row: 1;
  grid-column: 1;
  padding: 1rem 5rem;
}

.testimonial-portrait > img {
  width: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}

.testimonial-portrait > img:hover {
  transform: none;
}

.vertical-divider {
  width: 4px;
  height: calc(100% - 2rem);
  margin: 1rem 0;
  grid-row: 1;
  grid-column: 2;
  border-radius: 50%;
  background: linear-gradient(
    to bottom,
    rgba(134, 134, 134, 0) 0%,
    rgba(134, 134, 134, 0.7) 40%,
    rgba(134, 134, 134, 0.7) 60%,
    rgba(134, 134, 134, 0) 100%
  );
}

.testimonial-content {
  display: block;
  position: relative;
  padding: 1rem 3rem;
}

.quote-start {
  top: 0;
  float: left;
  color: var(--color-green);
}

.quote-start.right {
  transform: scaleX(-1);
}

.testimonial-excerpt {
  display: inline-block;
  position: relative;
  font-size: 2rem;
  padding: 1rem 0;
  width: 100%;
  font-family: var(--font-roboto);
  font-weight: bold;
  text-align: left;
}

.testimonial-excerpt.right {
  text-align: right;
}

.testimonial-full {
  display: block;
  position: relative;
  text-align: justify;
  font-family: var(--font-roboto);
  color: rgb(100, 100, 100);
  font-style: italic;
  font-size: 1.1rem;
  line-height: 1.3rem;
}

/* .testimonial-full:before {
  position: absolute;
  content: "";
  border-top: 0.1rem solid var(--color-green);
  width: 8rem;
  transform: translateY(-10px);
} 

.testimonial-full.right:before {
  transform: translateY(-10px) translateX(40.8rem);
}
  
*/

.testimonial-author {
  position: relative;
  font-size: 1rem;
  padding: 1rem 2rem;
  font-family: var(--font-roboto);
  font-weight: bold;
  float: inline-start;
}

.quote-end {
  position: relative;
  float: right;
  bottom: 0;
  color: var(--color-green);
  padding-top: 1rem;
}

.testimonial-author.right {
  float: inline-end;
}
