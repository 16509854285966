html,
body {
  width: 100%;
  height: 100%;
}

.ah-page {
  display: block;
  position: relative;
}

.ah-landing {
  display: block;
  position: relative;
  height: 400px;
}

.ah-landing-content {
  display: grid;
  align-content: center;
  justify-content: center;
  position: relative;
  height: 100%;
  z-index: 2;
}

.ah-landing-background {
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 10%,
      rgba(0, 0, 0, 0) 90%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../../images/landing-image.png") no-repeat;
  background-position: center 30%;
  background-size: auto 200%;
  /* filter: blur(2px); */
}

.ad-banner-text {
  font-size: 3rem;
  text-transform: uppercase;
  font-family: var(--font-main);
  color: rgb(75, 75, 75);
  background-color: rgb(239 239 239 / 50%);
  padding: 1rem 2rem 0rem 1rem;
}

.ad-banner-text > span {
  font-family: "Qwigley", cursive;
  font-weight: bold;
  font-style: normal;
  font-size: 7.5rem;
  color: var(--color-green);
}

.ah-available-homes {
  background-color: white;
}

.ah-available-homes-banner {
  display: grid;
  align-content: center;
  justify-content: center;
  padding: 1rem 0;
}

.ah-available-homes-banner > span {
  font-family: var(--font-roboto);
  font-style: italic;
  color: var(--color-green);
  font-weight: bold;
  font-size: 1.5rem;
}

.ah-available-homes-banner-divider {
  display: block;
  position: relative;
  height: 0.15rem;
  width: 50%;
  margin: 0.5rem auto 0 auto;
  background-color: var(--color-green);
}

.ah-listings {
  padding: 1rem 2rem;
}

.ah-explanation {
  display: grid;
  background-color: white;
  padding: 1rem 0 1.5rem 0;
}

.ah-explanation > span {
  grid-column: 1 / 3;
  font-family: var(--font-roboto);
  text-decoration: underline;
}

.ah-explanation-spec {
  grid-column: 1;
  padding: 0 0rem 0 1rem;
  position: relative;
  display: block;
}

.ah-explanation-custom {
  grid-column: 2;
  padding: 0 1rem 0 0em;
  position: relative;
  display: block;
}

.ah-explanation-custom,
.ah-explanation-spec {
  justify-items: center;
}

.ah-explanation-custom.compact,
.ah-explanation-spec.compact {
  grid-column: 1;
  padding: 0 1.5rem;
}
