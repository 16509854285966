.op-tile-container {
  display: block;
  position: relative;
  background-color: white;
}

.op-tile-container > span {
  position: absolute;
  font-family: Prata;
  top: -0.5rem;
  left: -0.5rem;
  color: white;
  font-size: 3rem;
  background-color: rgba(0, 128, 0, 0.75);
  padding: 0.5rem 1.5rem;
  border-radius: 50%;
  overflow: hidden;
}

.op-tile-content {
  display: grid;
  margin: 2rem 1rem;
  padding: 2rem 1rem;
  box-shadow: rgba(151, 151, 151, 0.25) 0 0 1rem;
  font-family: var(--font-roboto);
}

.op-tile-content.left {
  justify-items: self-start;
  text-align: left;
}

.op-tile-content.center span {
  text-align: center;
  justify-items: center;
}

.op-tile-content > span:first-child {
  font-family: var(--font-merriweather);
  font-style: italic;
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.op-tile-content.left > span:last-child {
  text-align: justify;
}
