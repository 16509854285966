html,
body {
  width: 100%;
  height: 100%;
}

.g-page {
  display: block;
  position: relative;
}

.g-landing {
  display: block;
  position: relative;
}

.g-landing-content {
  display: block;
  position: relative;
  align-content: center;
  align-items: baseline;
  height: 300px;
  z-index: 2;
}

.g-gallery-selector {
  display: grid;
  position: relative;
  height: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-content: center;
  padding: 1rem 2rem;
  row-gap: 1rem;
}

.g-gallery-category {
  display: block;
  position: relative;
  width: 18vw;
  max-width: 250px;
  height: 10rem;
  margin: 5% 10%;
  box-shadow: 0 0 10px 4px black;
  transition: width 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    margin 0.2s ease-in-out;
  z-index: 1;
}

.g-category-overlay {
  display: grid;
  position: absolute;
  height: 100%;
  width: 100%;
  align-content: center;
  justify-content: center;
  opacity: 0;
  background-color: white;
  transition: opacity 0.2s ease-in-out;
}

.g-category-overlay > span {
  user-select: none;
  font-family: var(--font-roboto);
  font-weight: bold;
  font-size: 1.6rem;
  color: green;
  text-transform: capitalize;
}

.g-gallery-category img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.g-gallery-category img:hover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  z-index: 3;
}

.g-gallery-category:hover {
  width: calc(100% + 5%);
  margin: 5% 0;
  box-shadow: 0px 0px 10px 8px white;
}

.g-gallery-category > a > .g-category-overlay:hover {
  opacity: 0.7;
}

.g-gallery-label {
  display: block;
  position: relative;
  grid-column: 1 / 5;
  font-family: var(--font-roboto);
  color: white;
  font-size: 1.5rem;
  text-shadow: 1px 3px 5px black;
}

.g-landing-background {
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 10%,
      rgba(0, 0, 0, 0) 90%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../../images/landing-image.png") no-repeat;
  background-position: center 30%;
  background-size: auto 200%;
  filter: blur(2px);
}

.g-gallery-info {
  display: grid;
  position: relative;
  background-color: white;
  padding: 2rem 1rem;
}

.g-gallery-info > span:first-child {
  text-transform: capitalize;
  font-family: var(--font-roboto);
  font-size: 3rem;
}

.g-gallery-info-divider {
  display: block;
  position: relative;
  height: 0.15rem;
  width: 7rem;
  margin: 0.5rem auto;
  background-color: var(--color-green);
}

.g-gallery-info > span:last-child {
  font-family: var(--font-roboto);
  font-size: 1.5rem;
}

.g-gallery-container {
  position: relative;
  background-color: white;
  padding: 2rem 1rem;
}

img {
  transition: transform 0.1s ease-in-out;
}

img:hover {
  transform: scale(1.05);
}

#page-nav {
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  justify-content: center;
}

ul#page-numbers li {
  font-size: 1.5rem;
  align-self: center;
  opacity: 0.8;
  cursor: pointer;
  user-select: none;
  line-height: 2.9rem;
}

ul#page-numbers li.current-page {
  font-weight: bold;
  opacity: 1;
  pointer-events: none;
}

ul#page-numbers li:not(:last-child) {
  margin-right: 1rem;
}

.page-nav-btn {
  color: var(--color-black);
  opacity: 0.3;
  font-size: 2rem;
  user-select: none;
  pointer-events: none;
}

.page-nav-btn.active {
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}
