#rotating-background-container {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.rotating-background {
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 0) 90%,
    rgba(0, 0, 0, 0.5) 100%
  );
  background-position: center;
  background-size: auto 120%;
}

/* .au-landing-background {
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 10%,
      rgba(0, 0, 0, 0) 90%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("images/landing-image.png") no-repeat;
  background-position: center 30%;
  background-size: auto 200%;
  filter: blur(2px);
} */

.rotating-background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rotating-background img:hover {
  transform: none;
}

#background-1 {
  z-index: 1;
}

#background-2 {
  z-index: 0;
}
