.nav-menu {
  position: relative;
  height: 100%;
  z-index: 999;
}

ul:not(.mobile-ul) {
  display: flex;
  position: relative;
  height: 100%;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  flex-wrap: nowrap;
}

.main-nav-header,
.sub-nav-header {
  flex-direction: row;
}

.sub-nav-header {
  width: 100%;
  background-color: rgba(from var(--color-black) R G B / 0.7);
}

.main-nav-footer,
.sub-nav-footer {
  flex-direction: column;
  align-items: flex-start;
}

.main-nav-footer.compact,
.sub-nav-footer.compact {
  font-size: 0.8rem;
}

li {
  display: flex;
  height: 100%;
  background-repeat: no-repeat;
}

.nav-header-container ul > li {
  padding: 0 0.25em;
  margin: 0 0.25rem;
}

.main-nav-header > li {
  background-image: linear-gradient(
    to bottom,
    rgba(from white R G B / 1) 75%,
    rgba(from white R G B / 0) 100%
  );
  transition: all 300ms ease-in;
  background-position: 100% 200%;
  background-size: 100% 200%;
}

.nav-footer-container ul > li {
  padding: 0.45em 0.25rem;
  margin: 0.1rem 0;
}

.main-nav-footer > li {
  background-image: linear-gradient(
    to right,
    rgba(from var(--color-white) R G B / 1) 75%,
    rgba(from var(--color-white) R G B / 0) 100%
  );
  transition: all 0.3s ease-in;
  background-position: 200% 100%;
  background-size: 200% 100%;
}

li > a {
  font-family: var(--font-menu);
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  text-decoration: none;
  transition: color 300ms ease-in;
  text-shadow: 2px 2px 4px black;
}

.main-nav-header li > a,
.sub-nav-header li > a {
  margin: auto;
}

.main-nav-footer li {
  width: 100%;
}

.main-nav-header > li.hover {
  background-position: 100% 150%;
  letter-spacing: 1px;
}

.main-nav-footer > li.hover {
  background-position: 150% 100%;
  letter-spacing: 1px;
}

li:active > a {
  color: var(--color-green);
  transition: none;
}

.submenu-indicator:after {
  content: "\2304";
  display: inline-block;
  text-shadow: none;
  transition: transform 0.3s ease-in-out;
  transform: rotate(180deg) translate(0, -3px);
}

li.active .submenu-indicator:after,
li.hover .submenu-indicator:after {
  transform: rotate(0deg) translate(0, -6px);
}

.main-nav-footer .submenu-indicator:after {
  transform: rotate(90deg) translate(-2px, -6px);
}
.main-nav-footer li.active .submenu-indicator:after,
.main-nav-footer li.hover .submenu-indicator:after {
  transform: rotate(-90deg) translate(2px, -3px);
}

/* MOBILE NAV CSS */

.mobile-nav {
  display: block;
  position: fixed;
  width: 50%;
  left: -50%;
  background-color: rgba(from var(--color-black) R G B / 0.7);
  height: 200px;
}

.mobile-nav.open {
  transition: all 0.3s ease-in-out;
  left: 0;
}
