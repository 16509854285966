:root {
  --color-black: #080608;
  --color-white: #f3f3f3;
  --color-green: #1d741e;
  --color-grey-1: #333333;
  --color-grey-2: #6e6e6e;
  --color-red: #741d1d;
  --color-cyan: #1d7374;

  --font-main: "Fjalla One", "Fjalla One Fallback";
  --font-menu: "Montserrat", sans-serif;
  --font-roboto: Roboto, "Roboto Fallback";
  --font-merriweather: Merriweather, "Merriweather Fallback";
}

/*
  Color picker options:
  - #1d741e
  - #1d7374
  - #5bab15
*/
