html,
body {
  width: 100%;
  height: 100%;
}

.fp-page {
  display: block;
  position: relative;
}

.fp-landing {
  display: block;
  position: relative;
  height: 400px;
}

.fp-landing-content {
  display: grid;
  align-content: center;
  justify-content: center;
  position: relative;
  height: 100%;
  z-index: 2;
}

.fp-banner-text {
  font-size: 3rem;
  text-transform: uppercase;
  font-family: var(--font-main);
  color: rgb(75, 75, 75);
  background-color: rgb(239 239 239 / 50%);
  padding: 1rem 2rem 0rem 1rem;
}

.fp-banner-text > span {
  font-family: "Qwigley", cursive;
  font-weight: bold;
  font-style: normal;
  font-size: 7.5rem;
  color: var(--color-green);
}

.fp-floorplans {
  background-color: white;
}

.fp-floorplans-banner {
  display: grid;
  align-content: center;
  justify-content: center;
  padding: 1rem 0 1.5rem 0;
}

.fp-floorplans-banner > span:first-child {
  font-family: var(--font-roboto);
  font-style: italic;
  color: var(--color-green);
  font-weight: bold;
  font-size: 1.5rem;
}

.fp-floorplans-banner-divider {
  display: block;
  position: relative;
  height: 0.15rem;
  width: 7rem;
  margin: 0.5rem auto 0 auto;
  background-color: var(--color-green);
}

.fp-floorplans-banner > span:last-child {
  font-family: var(--font-roboto);
  font-style: italic;
  padding: 2rem 10rem;
}

.fp-floorplans-banner.compact > span:last-child {
  font-family: var(--font-roboto);
  font-style: italic;
  padding: 2rem 5rem;
}

/*---------------------------------*/

#floorplan-container {
  display: block;
  position: relative;
  background-color: var(--color-white);
  box-shadow: 0 0 5px 5px var(--color-white);
  padding: 1rem;
}

.floorplan-tile {
  box-shadow: 2px 2px 3px 2px var(--color-grey-2);
  margin: 1.3rem;
  transition: all 200ms ease-in-out;
  border-radius: 1rem;
  overflow: hidden;
}

.floorplan-tile:hover {
  box-shadow: 5px 5px 8px 1px var(--color-grey-2);
  transform: scale(1.05);
}

.floorplan-tile > div {
  display: grid;
  padding: 0.5rem;
  cursor: pointer;
}

.floorplan-tile > img {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

#floorplan-details {
  display: grid;
  position: relative;
  padding: 1rem;
  background-color: white;
  grid-template-rows: 1fr auto;
  box-shadow: 0 0 5px 5px white;
}

#floorplan-details div {
  position: relative;
}

#close-details-btn {
  position: absolute;
  justify-self: end;
  margin: 1.5rem;
  z-index: 5;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 3vh;
  font-weight: bold;
}

#more-images-btn {
  position: absolute;
  bottom: 5%;
  left: 26%;
  width: 70%;
  z-index: 5;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}

#details-left {
  display: grid;
  position: relative;
  grid-column: 1;
}

#details-left > img {
  object-fit: contain;
  position: relative;
  height: auto;
  width: auto;
  pointer-events: none;
}

#details-right {
  display: grid;
  position: relative;
  grid-column: 2;
}

.slick-arrow::before {
  color: inherit;
}

#floorplan-images {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}

#video-stream {
  display: grid;
  background-color: black;
}

#floorplan-images div:hover img:hover {
  box-shadow: 5px 5px 8px 1px var(--color-grey-2);
  transform: scale(1);
  box-shadow: none;
  cursor: pointer;
}

#floorplan-images img {
  object-fit: contain;
  object-position: 50% 50%;
  display: block;
  height: 100%;
  width: 100%;
  background-color: var(--color-black);
}

/*-------------------------*/

#detail-banner {
  background-color: var(--color-white);
  font-size: 1.2rem;
  padding-bottom: 2rem;
  font-style: italic;
}

/*-------------------------*/

#floorplan-specs {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: auto auto 1fr 1fr;
  text-align: left;
  padding: 1rem;
}

#floorplan-specs span {
  font-size: 1rem;
  font-family: var(--font-roboto);
}

#floorplan-name {
  grid-row: 1;
  grid-column: 1 / 3;
  font-size: 2rem !important;
  font-weight: bold;
  font-style: italic;
  color: var(--color-green);
}

#floorplan-beds {
  grid-row: 2;
  grid-column: 1;
  padding: 0.5rem 0 1rem 0;
}

#floorplan-baths {
  grid-row: 2;
  grid-column: 2;
  padding: 0.5rem 0 1rem 0;
}

#floorplan-feet {
  grid-row: 2;
  grid-column: 3;
  padding: 0.5rem 0 1rem 0;
}

#floorplan-feet.compact {
  grid-column: 3 / 5;
}

#floorplan-feet span:last-child:after {
  min-width: 175px;
  white-space: pre-wrap;
  word-break: break-all;
}

[class^="hint--"] {
  text-decoration: underline dotted;
  text-underline-offset: 2px;
}

.floorplan-line {
  display: block;
  position: relative;
  grid-row: 3;
  grid-column: 1 / 4;
  bottom: 0.5rem;
  height: 0.1rem;
  width: 95%;
  background-color: var(--color-green);
}

#floorplan-specs-blurb {
  grid-row: 3 / 5;
  grid-column: 1 / 6;
  padding: 0 1rem 0 0;
  font-style: italic;
  font-size: 1.1rem;
}

#floorplan-specs-contact {
  display: grid;
  justify-items: center;
  text-align: center;
  font-family: var(--font-roboto);
  border: var(--color-green) solid 2px;
  border-radius: 0.4rem;
  box-shadow: 0 0 4px 0px grey;
  grid-row: 1 / 4;
  grid-column: 6 / 8;
  padding: 1rem;
}

#floorplan-specs-contact.compact {
  grid-row: 5;
  grid-column: 1 / 4;
  margin: 1rem 0;
  margin-right: 1rem;
}

#floorplan-specs-contact-btn {
  display: grid;
  width: calc(100% - 2rem - 4px);
  /* height: calc(100% - 4rem); */
  justify-items: center;
  border: var(--color-green) solid 2px;
  border-radius: 0.4rem;
  box-shadow: 0 0 4px 0px grey;
  grid-row: 4 / 6;
  grid-column: 6 / 8;
  margin: 1rem 0;
  padding: 1rem;
}

#floorplan-specs-contact-btn.compact {
  grid-row: 5;
  grid-column: 4 / 6;
  margin: 1rem 0;
}

#floorplan-specs-contact-btn > a {
  display: block;
  position: relative;
  border-radius: 15px;
  margin: auto;
  padding: 6% 8%;
  text-decoration: none;
  background-color: var(--color-black);
  color: white;
  font-weight: bolder;
  font-size: 130%;
}
