html,
body {
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
}

.te-page {
  display: block;
  position: relative;
}

.te-landing {
  display: block;
  position: relative;
  height: 400px;
}

.te-landing-content {
  display: grid;
  align-content: center;
  justify-content: center;
  position: relative;
  height: 100%;
  z-index: 2;
}

.te-banner-text {
  font-size: 3rem;
  text-transform: uppercase;
  font-family: var(--font-main);
  color: rgb(75, 75, 75);
  background-color: rgb(239 239 239 / 50%);
  padding: 1rem 2rem 0rem 1rem;
}

.te-banner-text > span {
  font-family: "Qwigley", cursive;
  font-weight: bold;
  font-style: normal;
  font-size: 7.5rem;
  color: var(--color-green);
}

.te-banner-text > span.compact {
  font-size: 4.7rem;
}

.te-landing-background {
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 10%,
      rgba(0, 0, 0, 0) 90%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../../images/landing-image.png") no-repeat;
  background-position: center 30%;
  background-size: auto 200%;
  /* filter: blur(2px); */
}

.te-contact-container {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  height: 600px;
  background-color: var(--color-white);
}
